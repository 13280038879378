import React from 'react';
import { motion } from 'framer-motion';


const Logo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#77d47e" viewBox="0 0 22 21" style={{ width: '45px', height: '64px' }}>
  <path d="M5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.560c1.11.300 2.229.655 2.887.870a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.990a11.775 11.775 0 0 1-2.517 2.453a7.159 7.159 0 0 1-1.048.625c-.280.132-.581.240-.829.240s-.548-.108-.829-.240a7.158 7.158 0 0 1-1.048-.625a11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692a1.54 1.54 0 0 1 1.044-1.262a62.456 62.456 0 0 1 2.185-.870z" />
</svg>
);

export default Logo;
